<template>
    <div>
        <div class="ideaGallery">
            <div class="ideaGallery__nav" v-if="showNavigation == true">
                <ul class="list-inline">
                    <li>
                        <button
                            @click.prevent="slidePrev"
                            class="ideaGallery__nav--previous"
                        >
                            <img
                                loading="lazy"
                                src="@/assets/images/banner-arrow.svg"
                                alt=""
                            />
                        </button>
                    </li>
                    <li>
                        <button
                            @click.prevent="slideNext"
                            class="bideaGallery__nav--next"
                        >
                            <img
                                loading="lazy"
                                src="@/assets/images/banner-arrow.svg"
                                alt=""
                            />
                        </button>
                    </li>
                </ul>
            </div>
            <VueSlickCarousel
                v-bind="gallerySettings"
                ref="carousel"
                v-if="medias"
            >
                <div
                    class="ideaGallery__slide"
                    v-for="media in medias"
                    :key="media.id"
                >
                    <div
                        class="ideaGallery__slide--image video"
                        v-if="getFileType(media.file) == 'video'"
                    >
                        <video controls>
                            <source :src="media.file" type="video/mp4" />
                        </video>
                    </div>

                    <div class="ideaGallery__slide--image" v-else>
                        <img loading="lazy" :src="media.file" alt="" />
                    </div>
                </div>
                <!-- <div class="ideaGallery__slide">
          <div class="ideaGallery__slide--image">
            <img src="@/assets/images/idea-detail-image-800x350.png" alt="" />
          </div>
        </div>
        <div class="ideaGallery__slide">
          <div class="ideaGallery__slide--image">
            <img src="@/assets/images/idea-detail-image-800x350.png" alt="" />
          </div>
        </div>
        <div class="ideaGallery__slide video">
          <div class="ideaGallery__slide--image">
            <img src="@/assets/images/idea-detail-image-800x350.png" alt="" />
            <span class="ideaGallery__slide--playIcon">
              <svg
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="15px"
                height="19px"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.189,8.724 L2.413,1.177 C2.065,0.966 1.632,1.002 1.306,1.002 C-0.000,1.002 0.006,2.027 0.006,2.287 L0.006,17.712 C0.006,17.931 -0.000,18.997 1.306,18.997 C1.632,18.997 2.065,19.032 2.413,18.821 L14.189,11.274 C15.156,10.689 14.989,9.999 14.989,9.999 C14.989,9.999 15.156,9.309 14.189,8.724 Z"
                />
              </svg>
            </span>
          </div>
        </div>
        <div class="ideaGallery__slide">
          <div class="ideaGallery__slide--image">
            <img src="@/assets/images/idea-detail-image-800x350.png" alt="" />
          </div>
        </div>
        <div class="ideaGallery__slide">
          <div class="ideaGallery__slide--image">
            <img src="@/assets/images/idea-detail-image-800x350.png" alt="" />
          </div>
        </div>
        <div class="ideaGallery__slide">
          <div class="ideaGallery__slide--image">
            <img src="@/assets/images/idea-detail-image-800x350.png" alt="" />
          </div>
        </div> -->
            </VueSlickCarousel>
            <div class="lightbox" v-if="isLightBoxOpen">
                <a class="lightbox-close" @click="closeLightBox()">
                    <svg
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 409.81 409.81"
                    >
                        <use xlink:href="#menu-close-icon"></use>
                    </svg>
                </a>
                <img class="lightbox-image" :src="lightBoxImage" />
            </div>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
    name: 'ideaGallery',
    components: {
        VueSlickCarousel,
    },
    props: {
        medias: Array,
        showNavigation: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            gallerySettings: {
                arrows: false,
                dots: true,
                centerMode: true,
                variableWidth: false,
                slidesToShow: 3,
                slidesToScroll: 1,
                focusOnSelect: true,
                adaptiveHeight: true,
                responsive: [
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 576,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            centerPadding: '60px',
                        },
                    },
                    {
                        breakpoint: 420,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ],
            },
            isLightBoxOpen: false,
            lightBoxImage: null,
        }
    },
    methods: {
        openLightBox(image) {
            this.lightBoxImage = image
            this.isLightBoxOpen = true
            this.$emit('setLightBoxOpen', true)
        },
        closeLightBox() {
            this.lightBoxImage = null
            this.isLightBoxOpen = false
            this.$emit('setLightBoxOpen', false)
        },
        slidePrev() {
            this.$refs.carousel.prev()
        },
        slideNext() {
            this.$refs.carousel.next()
        },
        getFileType(file) {
            if (
                file.split('.').pop() == 'mp4' ||
                file.split('.').pop() == 'webm'
            ) {
                return 'video'
            } else {
                return ''
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.lightbox {
    transform: scale(1);
    z-index: 9999;
    position: fixed;
    width: 100%;
    height: 100vh;
    left: 0;
    top: 0;
    background: #000;
    padding: 1.5625rem 3.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    .lightbox-close {
        position: absolute;
        top: 2%;
        right: 2%;
        color: #fff;
        svg {
            transition: all 0.5s ease;
            width: 29px;
            height: auto;
            overflow: hidden;
            vertical-align: middle;
        }
        @media screen and (min-width: 1025px) {
            svg:hover {
                transform: rotate(180deg);
            }
        }
    }
    &-image {
        width: auto;
        height: auto;
    }
}
.ideaGallery {
    position: relative;
    $self: &;
    &__slide {
        text-align: center;
        margin: 0 2px;
        // width: 260px;

        &--image {
            @media screen and (min-width: 1700px) {
                //     width: 94%;
                width: 714px;
            }
            @media screen and (min-width: 1400px) and (max-width: 1600px) {
                //     width: 94%;
                width: 514px;
            }
            @media screen and (min-width: 1200px) and (max-width: 1401px) {
                //     width: 94%;
                width: 470px;
            }
            @media screen and (min-width: 974px) and (max-width: 1201px) {
                //     width: 94%;
                width: 370px;
            }
            // @media screen and (max-width: 975px) {
            //     //     width: 94%;
            //     width: 270px;
            // }
            position: relative;
            video {
                transform: scale(0.55);
                @media screen and (min-width: 1700px) {
                    transform: scale(0.377);
                    width: 100%;
                }
                max-width: 100%;
                // width: 100%;
                height: 358px;
            }
            // @include flex(center,center);
            img {
                transform: scale(0.5);
                @media screen and (min-width: 1700px) {
                    transform: scale(0.377);
                    // width: 100%;
                }
                // max-width: 100%;
                width: 100%;
                // height: 358px;
                background: #fff;
            }
            // video {
            //   transform: scale(0.5);
            //   max-width: 100%;
            // }
        }
        &--playIcon {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border: solid 4px #fff;
            position: absolute;
            @include center-both();
            color: #fff;
            @include flex(center, center);
            cursor: pointer;
            svg {
                width: 15px;
                height: auto;
            }
            @media screen and (min-width: 1025px) {
                &:hover {
                    color: var(--secondary);
                    border-color: var(--secondary);
                }
            }
        }
    }
    &__nav {
        position: absolute;
        top: -20px;
        right: 0px;
        z-index: 99;
        right: calc((100% - 1240px) / 2);
        button {
            background: transparent;
            border: 0px;
            img {
                width: 28px;
            }
            @media screen and (min-width: 1025px) {
                &:hover {
                    opacity: 0.7;
                }
            }
        }
        li:not(:last-child) {
            position: relative;
            padding-right: 10px;
            margin-right: 10px;
            &:after {
                position: absolute;
                content: '';
                width: 1px;
                height: 35px;
                background: #67676a;
                @include center-vertical();
                right: 0px;
            }
        }
        &--previous {
            img {
                transform: rotate(180deg);
            }
            // video {
            //   transform: rotate(180deg);
            // }
        }
    }
    .slick-current {
        #{$self}__slide {
            width: 750px;
            &--image {
                @include flex(center, center);

                img {
                    transform: scale(1);
                    max-width: 100%;
                    border: solid 2px #009b74;
                }
                video {
                    transform: scale(1);
                    @media screen and (max-width: 1025px) {
                        width: 100%;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1300px) {
        &__nav {
            right: calc((100% - 1100px) / 2);
        }
    }
    @media screen and (max-width: 1199px) {
        &__nav {
            right: calc((100% - 992px) / 2);
        }
    }
    @media screen and (max-width: 991px) {
        .slick-slider {
            margin-top: rem(40px);
        }
        &__slide {
            &--playIcon {
                width: 50px;
                height: 50px;
            }
            &--image {
                img {
                    transform: scale(0.8);
                }
            }
        }
        &__nav {
            right: 15px;
            top: -30px;
        }
    }
    @media screen and (max-width: 767px) {
        &__nav {
            top: 10px;
            left: 50% !important;
            right: auto !important;
            transform: translateX(-50%) !important;
            li {
                &:not(:last-child) {
                    &:after {
                        height: 20px;
                    }
                }
            }
        }
    }
}

// ar style
.ar {
    .ideaGallery {
        $self: &;
        &__nav {
            position: absolute;
            left: 0px;
            right: auto;
            left: calc((100% - 1240px) / 2);
            right: auto;
            li:not(:last-child) {
                padding-left: 10px;
                margin-left: 10px;
                padding-right: 0;
                margin-right: 0;
                &:after {
                    left: 0px;
                    right: auto;
                }
            }
            &--previous {
                img {
                    transform: rotate(180deg);
                }
            }
            button {
                transform: rotate(180deg);
            }
        }
        .slick-current {
            #{$self}__slide {
                width: 750px;
                &--image {
                    @include flex(center, center);
                    border: solid 2px #009b74;
                    img {
                        transform: scale(1);
                        max-width: 100%;
                    }
                }
            }
        }
        @media screen and (max-width: 1300px) {
            &__nav {
                left: calc((100% - 1100px) / 2);
                right: auto;
            }
        }
        @media screen and (max-width: 1199px) {
            &__nav {
                left: calc((100% - 992px) / 2);
                right: auto;
            }
        }
        @media screen and (max-width: 991px) {
            &__nav {
                left: 15px;
                right: auto;
            }
        }
    }
}
</style>
