<template>
    <div
        class="ideaDetail"
        :style="isLightBoxOpen ? { 'z-index': '999' } : { 'z-index': '5' }"
        :class="{
            'media-available': medias.length,
        }"
    >
        <!-- Banner -->
        <base-inner-banner :banner-image="pageBanner"></base-inner-banner>
        <!-- Banner -->

        <!-- Scroll Spy -->
        <base-sections-scroll
            :page-sections="pageSections"
        ></base-sections-scroll>
        <!-- Scroll Spy -->

        <!-- Content wrapper -->
        <div class="content-wrapper">
            <div class="page__head" id="introduction">
                <div class="container">
                    <div class="ideaDetail__head">
                        <h1 class="ideaDetail__head--title">
                            {{ ideaObject.title }}
                        </h1>
                        <p class="ideaDetail__head--date">
                            {{ ideaObject.created | dateDayMonthYear }}
                        </p>
                        <p class="ideaDetail__head--shortdes"></p>
                        <div
                            class="ideaDetail__user"
                            v-if="ideaObject && ideaObject.owner_details"
                        >
                            <div class="ideaDetail__user--image">
                                <img
                                    v-if="ideaObject.owner_details.photo"
                                    loading="lazy"
                                    :src="ideaObject.owner_details.photo"
                                    alt=""
                                />
                                <img
                                    v-else
                                    loading="lazy"
                                    src="@/assets/images/svg/person.svg"
                                    alt=""
                                />
                            </div>
                            <div class="ideaDetail__user--name">
                                <p>
                                    {{ ideaObject.owner_details.display_name }}
                                </p>
                                <p class="designation">
                                    {{ ideaObject.owner_details.title }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="page-contents">
                <!-- Image Gallery -->
                <div
                    class="ideaDetail__gallery"
                    id="ideaGallery"
                    v-if="medias.length"
                >
                    <gallery-idea
                        :medias="medias"
                        :show-navigation="false"
                        @setLightBoxOpen="setLightBoxOpen"
                    ></gallery-idea>
                </div>
                <!-- Image Gallery -->

                <div class="container">
                    <!-- Idea Detail Title -->
                    <div class="ideaDetail__title">
                        <h2>{{ ideaObject.title }}</h2>
                    </div>
                    <!-- Idea Detail Title -->
                    <!-- Idea Detail Description -->
                    <div class="ideaDetail__description">
                        <p v-html="ideaObject.description"></p>
                    </div>
                    <!-- Idea Detail Description -->

                    <!-- Dynamic Form Fields -->
                    <div
                        class="ideaDetail__dynamic-fields"
                        v-if="ideaObject && ideaObject.form_values"
                    >
                        <div
                            v-for="(value, key) in ideaObject.form_values"
                            :key="key"
                        >
                            <div v-if="formatData(value.value)">
                                <h2>{{ value[`label_${$i18n.locale}`] }}</h2>
                                <p>{{ formatData(value.value) }}</p>
                            </div>
                        </div>
                    </div>
                    <!-- Dynamic Form Fields -->
                    <!-- <div class="row align-items-center col-gap-90"> -->
                    <!-- Post Comments -->
                    <!-- <div class="col-lg-6">
                            <base-post-comments
                                @click="addComment"
                                :object="ideaObject"
                            />
                        </div> -->
                    <!-- Post Comments -->

                    <!-- Ideas Reviews -->
                    <!-- <div class="col-lg-6">
                            <base-reviews
                                v-if="idea.id"
                                :object="idea"
                                @social="social"
                            ></base-reviews>
                        </div> -->
                    <!-- Ideas Reviews -->
                    <!-- </div> -->

                    <!-- Idea Details tabs -->
                    <div class="ideaDetail__details" id="timeline">
                        <timeline-description></timeline-description>
                    </div>
                    <!-- Idea Details tabs -->

                    <!-- <div class="ideaDetail__reviews" id="reviews">
                        <div class="position-relative">
                            <div class="greyBg right"></div>
                            <reviews
                                ref="refReviews"
                                @addComment="addComment"
                                @likeComment="likeComment"
                            />
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import imgMedium from '@/assets/images/banners/about-zainiac-banner-1200X950.png'
import imgLarge from '@/assets/images/banners/about-zainiac-banner-1600X1000.png'
import imgBig from '@/assets/images/banners/about-zainiac-banner-1900X850.png'
import { IdeasHelper } from '@/common/crud-helpers/ideas'
// import Gallery from '@/modules/ideas/components/details/Gallery.vue'
// import Reviews from '@/modules/ideas/components/details/Reviews.vue'
import TimelineDescription from '@/modules/ideas/components/details/TimelineDescription.vue'
import { ideasAddComment } from '@/modules/ideas/mixins/ideasAddComment.js'
import { ideasReviews } from '@/modules/ideas/mixins/ideasReviews.js'
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'

import GalleryIdea from '../components/details/GalleryIdea.vue'

export default {
    mixins: [ideasReviews, ideasAddComment],
    components: {
        TimelineDescription,
        // Gallery,
        // Reviews,
        GalleryIdea,
    },
    data() {
        return {
            pageBanner: {
                imgBig: imgBig,
                imgLarge: imgLarge,
                imgMedium: imgMedium,
            },
            gallerySettings: {
                arrows: false,
                dots: false,
                centerMode: true,
                variableWidth: false,
                slidesToShow: 3,
                slidesToScroll: 1,
                focusOnSelect: true,
            },
            pageSections: [
                {
                    title: this.$t('Introduction'),
                    id: 'introduction',
                },
                {
                    title: this.$t('Gallery'),
                    id: 'ideaGallery',
                },
                {
                    title: this.$t('Timeline & Description'),
                    id: 'timeline',
                },
                {
                    title: this.$t('Reviews'),
                    id: 'reviews',
                },
            ],
            medias: [],
            idea: {},
            isLightBoxOpen: false,
        }
    },
    created() {
        this.getIdea()
    },
    methods: {
        formatData(value) {
            let fomratedValue = ''
            if (Array.isArray(value) === true) {
                fomratedValue = value
                    .map(item => {
                        return item
                    })
                    .join(', ')
            } else if (typeof value === 'object') {
                fomratedValue = Object.keys(value)
                    .filter(item => value[item])
                    .join(', ')
            } else fomratedValue = value
            return fomratedValue
        },
        setLightBoxOpen(val) {
            this.isLightBoxOpen = val
        },
        getIdea() {
            if (this.$route.params.uuid) {
                IdeasHelper.getIdea(this.$route.params.uuid)
            }
        },
    },
    computed: {
        ...mapGetters(['ideaObject']),
    },
    watch: {
        ideaObject() {
            this.idea = cloneDeep(this.ideaObject)
            let previewImage = {}
            previewImage.file = this.idea.preview_image

            let allMedias = this.idea.previewImage
                ? _.concat([previewImage], this.ideaObject.medias)
                : this.ideaObject.medias
            this.medias = cloneDeep(allMedias)
        },
    },
}
</script>

<style lang="scss" scoped>
.media-available {
    .content-wrapper {
        .page__head {
            min-height: 225px;
            @media screen and (max-width: 767px) {
                min-height: 250px;
            }
        }
    }
}
.content-wrapper {
    .page__head {
        min-height: 450px;
        @media screen and (max-width: 767px) {
            min-height: 285px;
        }
    }
}
.ideaDetail {
    /deep/ {
        .page-contents {
            padding-bottom: rem(60px);
            padding-top: rem(50px);
            @media screen and (max-width: 1500px) {
                padding-top: rem(70px);
            }
            @media screen and (max-width: 991px) {
                padding-top: rem(90px);
            }
            @media screen and (max-width: 767px) {
                padding-top: rem(0px);
            }
        }
        .filesList {
            padding: 0 !important;
        }
        .slick-current {
            .ideaGallery {
                &__slide {
                    &--image {
                        img {
                            height: auto;
                        }
                    }
                }
            }
        }
        .ideaGallery {
            .lightbox-image {
                width: 100%;
            }
            &__slide {
                &--image {
                    height: 300px;
                    @media screen and (min-width: 1700px) {
                        height: 410px !important;
                    }
                    img {
                        background: transparent;
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                    }
                }
                @media screen and (max-width: 991px) {
                    height: 190px;
                    &--image {
                        height: 190px;
                    }
                }
            }
        }
    }
    &.media-available {
        /deep/.page-banner {
            @media screen and (max-width: 767px) {
                --h: 650px;
            }
        }
    }
    /deep/.page-banner {
        --h: 750px;
        @media screen and (max-width: 1600px) {
            --h: 750px;
        }
        @media screen and (max-width: 991px) {
            --h: 750px;
        }
        @media screen and (max-width: 991px) {
            --h: 750px;
        }
        @media screen and (max-width: 767px) {
            --h: 450px;
        }
    }
    &__head {
        @media screen and (min-width: 1199px) {
            margin-left: 25px;
        }
        &--title {
            font-size: rem(58px);
            font-weight: 900;
            color: #efe710;
            text-transform: uppercase;
        }
        &--date {
            font-size: rem(24px);
            font-weight: 700;
            color: #fff;
            margin-bottom: rem(30px);
        }
        &--shortdes {
            font-size: rem(20px);
            color: #fff;
            font-weight: 400;
            @include truncate(3);
        }
    }
    &__user {
        @include flex(center, flex-start);
        &--image {
            width: 110px;
            height: 110px;
            overflow: hidden;
            border-radius: 50%;
            margin-right: rem(20px);
            img {
                max-width: 100%;
                height: 100%;
            }
        }
        &--name {
            color: #fff;
            p {
                font-size: rem(21px);
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: rem(5px);
                &.designation {
                    font-size: rem(15px);
                }
            }
        }
    }
    &__title {
        @media screen and (max-width: 767px) {
            margin-top: 20px;
        }
        h2 {
            color: var(--primary);
            font-size: rem(24px);
            font-weight: 900;
            margin-bottom: rem(18px);
            text-transform: uppercase;
        }
    }
    &__description {
        color: #333333;
        font-size: rem(16px);
        margin-top: rem(35px);
        margin-bottom: rem(35px);
        p {
            white-space: pre-wrap;
            line-height: 1.5;
        }
    }
    &__dynamic-fields {
        h2 {
            color: var(--primary);
            font-size: rem(18px);
            font-weight: 900;
            text-transform: uppercase;
        }
        p {
            white-space: pre-wrap;
            color: #333333;
            line-height: 1.5;
            text-align: justify;
            overflow-wrap: break-word;
        }
    }
    &__gallery {
        margin-bottom: 1rem;
        margin-top: 2rem;
        /deep/ {
            .ideaGallery {
                padding: 0px rem(35px) 0 rem(35px);
            }
            .slick-dots {
                padding-top: 1rem;
                position: sticky;
                li {
                    &.slick-active {
                        button::before {
                            color: black;
                            opacity: 1;
                            border: 1px solid #009b74;
                            border-radius: 50%;
                        }
                    }
                    button::before {
                        color: #009b74;
                        opacity: 1;
                    }
                }
            }
        }
    }
    &__details {
        margin-top: rem(40px);
        /deep/ .ideas-tabs {
            .nav-tabs {
                .nav-item {
                    display: inline-block;
                    flex: 1;
                    .nav-link {
                        padding-left: 0;
                        padding-right: 0;
                        text-align: center;
                    }
                }
            }
            @media screen and (max-width: 767px) {
                .nav-tabs {
                    .nav-item {
                        .nav-link {
                            font-size: rem(17px);
                        }
                    }
                }
            }
        }
    }
    &__reviews {
        .greyBG {
            --h: 450px;
            top: rem(45px);
        }
        /deep/.tab-content {
            padding: 0 !important;
            margin-top: rem(50px) !important;
        }
        /deep/.scrollWrapper {
            padding: rem(32px) 0 rem(30px) rem(40px);
            @media screen and (max-width: 767px) {
                padding: rem(20px) 0 rem(20px) rem(20px);
            }
        }
    }
    @media screen and (max-width: 991px) {
        &__head {
            &--title {
                font-size: rem(48px);
            }
        }
        &__user {
            &--image {
                width: 80px;
                height: 80px;
            }
        }
        /deep/ {
            .postComments {
                margin-bottom: rem(20px);
            }
            .likes-wrapper {
                ul {
                    justify-content: center;
                }
            }
        }
    }
    @media screen and (max-width: 767px) {
        &__head {
            &--title {
                font-size: rem(35px);
            }
        }
    }
    @media screen and (max-width: 575px) {
        &__head {
            &--title {
                font-size: rem(30px);
            }
        }
    }
    /deep/ {
        .timeline {
            &__item {
                &:nth-child(4) {
                    &:before {
                        background-size: 100% 100%;
                    }
                }
                &:nth-child(3) {
                    &:after {
                        right: -22px;
                    }
                }
                &.completed:last-child:before {
                    transform: translate(-50%, -50%) rotate(180deg);
                }
            }
        }
    }
    @media screen and (max-width: 1199px) {
        /deep/ {
            .timeline {
                justify-content: center;
                &__item {
                    width: 185px;
                    height: 185px;
                    &--name {
                        font-size: rem(19px);
                        min-height: 2rem;
                    }
                    &--date {
                        font-size: rem(18px);
                    }
                    &:not(:last-child) {
                        margin-right: 12px;
                        &:after {
                            width: 30px;
                            height: 30px;
                            right: -20px;
                        }
                    }
                    &:nth-child(4) {
                        &:before {
                            background-size: 98%;
                        }
                    }
                }
            }
            .scrollWrapper {
                &.likes {
                    height: 485px;
                }
            }
        }
    }
    @media screen and (max-width: 991px) and (min-width: 768px) {
        /deep/ {
            .timeline {
                max-width: 720px;
                margin: auto;
                &__item {
                    width: 130px;
                    height: 130px;
                    &:not(:last-child) {
                        &:after {
                            right: -14px;
                            width: 18px;
                            height: 18px;
                            background-size: 12px, 13px;
                        }
                    }
                    &.unactive {
                        &:after {
                            background-size: 7px, 15px !important;
                        }
                    }
                    &--icon {
                        height: 40px;
                        margin-bottom: rem(5px);
                        svg {
                            width: 40px;
                        }
                    }
                    &--name {
                        font-size: rem(17px);
                        line-height: 1.2;
                        min-height: rem(32px);
                    }
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        /deep/ {
            .ideaDetail__head {
                margin-left: 0;
            }
        }
    }
    @media screen and (max-width: 767px) {
        /deep/ {
            .timeline {
                flex-direction: column;
                &__item {
                    width: 170px;
                    height: 170px;
                    margin: 0 0 rem(15px) 0;
                    &--shadow {
                        display: none;
                    }
                    &:before {
                        background-size: auto !important;
                        transform: rotate(90deg) translate(-50%, 50%) !important;
                        height: 100%;
                    }

                    &:nth-child(4) {
                        &:after {
                            transform: rotate(90deg) translateY(50%) !important;
                        }
                        &:before {
                            background-size: 98% !important;
                        }
                    }
                    &:not(:last-child) {
                        margin-right: 0 !important;
                        &:after {
                            bottom: -20px;
                            top: auto;
                            left: 50%;
                            right: auto;
                            transform: translateX(-50%);
                        }
                    }
                    &--name {
                        min-height: rem(30px);
                    }
                    &.unactive {
                        &:nth-child(2),
                        &:nth-child(3) {
                            &:after {
                                bottom: -32px !important;
                                left: 49% !important;
                            }
                        }
                        &:nth-child(3) {
                            &:before {
                                margin-left: -2px !important;
                            }
                        }
                    }
                    &.completed:last-child:before {
                        transform: translate(-50%, -50%) rotate(270deg) !important;
                    }
                    &.completed:nth-child(4):after {
                        transform: rotate(0deg) translateX(-50%) !important;
                    }
                }
            }
        }
    }
}

// @media screen and (max-width: 1700px) {
//     /deep/ {
//         .ideaDetail__head {
//             // margin-left: rem(180px);
//         }
//     }
// }
// @media screen and (max-width: 1500px) and (min-width: 992px) {
//     /deep/ {
//         .ideaDetail__head {
//             margin-left: rem(230px);
//         }
//     }
// }
@media screen and (max-width: 1199px) {
    /deep/ {
        .timeline {
            justify-content: center;
            &__item {
                width: 185px;
                height: 185px;
                &--name {
                    font-size: rem(19px);
                    min-height: 2rem;
                }
                &--date {
                    font-size: rem(18px);
                }
                &:not(:last-child) {
                    margin-right: 12px;
                    &:after {
                        width: 30px;
                        height: 30px;
                        right: -20px;
                    }
                }
                &:nth-child(4) {
                    &:before {
                        background-size: 98%;
                    }
                }
            }
        }
        .scrollWrapper {
            &.likes {
                height: 485px;
            }
        }
    }
}
@media screen and (max-width: 991px) and (min-width: 768px) {
    /deep/ {
        .timeline {
            max-width: 720px;
            margin: auto;
            &__item {
                width: 130px;
                height: 130px;
                &:not(:last-child) {
                    &:after {
                        right: -14px;
                        width: 18px;
                        height: 18px;
                        background-size: 12px, 13px;
                    }
                }
                &.unactive {
                    &:after {
                        background-size: 7px, 15px !important;
                    }
                }
                &--icon {
                    height: 40px;
                    margin-bottom: rem(5px);
                    svg {
                        width: 40px;
                    }
                }
                &--name {
                    font-size: rem(17px);
                    line-height: 1.2;
                    min-height: rem(32px);
                }
            }
        }
    }
}
@media screen and (max-width: 991px) {
    /deep/ {
        .ideaDetail__head {
            margin-left: 0;
        }
    }
}
@media screen and (max-width: 767px) {
    /deep/ {
        .timeline {
            flex-direction: column;
            &__item {
                width: 170px;
                height: 170px;
                margin: 0 0 rem(15px) 0;
                &--shadow {
                    display: none;
                }
                &:before {
                    background-size: auto !important;
                    transform: rotate(90deg) translate(-50%, 50%) !important;
                    height: 100%;
                }

                &:nth-child(4) {
                    &:after {
                        transform: rotate(90deg) translateY(50%) !important;
                    }
                    &:before {
                        background-size: 98% !important;
                    }
                }
                &:not(:last-child) {
                    margin-right: 0 !important;
                    &:after {
                        bottom: -20px;
                        top: auto;
                        left: 50%;
                        right: auto;
                        transform: translateX(-50%);
                    }
                }
                &--name {
                    min-height: rem(30px);
                }
                &.unactive {
                    &:nth-child(2),
                    &:nth-child(3) {
                        &:after {
                            bottom: -32px !important;
                            left: 49% !important;
                        }
                    }
                    &:nth-child(3) {
                        &:before {
                            margin-left: -2px !important;
                        }
                    }
                }
            }
        }
    }
}

// ar style

.ar {
    .ideaDetail {
        &__head {
            @media screen and (min-width: 1199px) {
                margin-right: 25px;
                margin-left: 0;
            }
        }
        &__user {
            &--image {
                margin-left: rem(20px);
                margin-right: 0;
            }
        }
        &__reviews {
            /deep/.scrollWrapper {
                padding: rem(32px) rem(40px) rem(30px) 0;
                @media screen and (max-width: 767px) {
                    padding: rem(20px) rem(20px) rem(20px) 0;
                }
            }
        }
        /deep/ {
            .timeline {
                &__item {
                    &:nth-child(3) {
                        &:after {
                            left: -22px;
                            right: auto;
                        }
                    }
                    &.completed:last-child:before {
                        transform: translate(-50%, -50%) rotate(0deg);
                    }
                }
            }
        }
    }
    // @media screen and (max-width: 1700px) {
    //     .ideaDetail__head {
    //         margin-right: rem(180px);
    //         margin-left: 0;
    //     }
    // }
    // @media screen and (max-width: 1500px) and (min-width: 992px) {
    //     .ideaDetail__head {
    //         margin-right: rem(230px);
    //         margin-left: 0;
    //     }
    // }
    @media screen and (max-width: 1199px) {
        .ideaDetail {
            /deep/ {
                .timeline {
                    &__item {
                        &:not(:last-child) {
                            margin-left: 12px;
                            margin-right: 0;
                            &:after {
                                left: -20px;
                                right: auto;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 991px) and (min-width: 768px) {
        .ideaDeatil {
            /deep/ {
                .timeline {
                    &__item {
                        &:not(:last-child) {
                            &:after {
                                left: -14px;
                                right: auto;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        .ideaDetail__head {
            margin-right: 0 !important;
        }
    }
    @media screen and (max-width: 767px) {
        .ideaDetail {
            /deep/ {
                .timeline {
                    flex-direction: column;
                    &__item {
                        width: 170px;
                        height: 170px;
                        margin: 0 0 rem(15px) 0;
                        &--shadow {
                            display: none;
                        }
                        &:before {
                            background-size: auto !important;
                            transform: rotate(90deg) translate(-50%, 50%) !important;
                            height: 100%;
                        }
                        &:nth-child(4) {
                            &:after {
                                transform: rotate(90deg) translateY(50%) !important;
                            }
                            &:before {
                                background-size: 98% !important;
                            }
                        }
                        &:not(:last-child) {
                            margin-left: 0 !important;
                            &:after {
                                bottom: -20px;
                                top: auto;
                                left: 50%;
                                right: auto;
                                transform: translateX(-50%);
                            }
                        }
                        &.completed:last-child:before {
                            transform: translate(-50%, -50%) rotate(270deg) !important;
                        }
                    }
                }
            }
        }
    }
}
</style>
<style scoped>
@media (min-width: 1200px) {
    .ideaGallery__slide--image img {
        height: auto !important;
    }
}
</style>
